<template lang="pug">
Accordion(fix)
  template(#title) {{ $t('font') }}
  template(#toggle)
    OmButton(iconOnly ghost icon="upload" small @click="$modal.show('custom-font-upload-v2')")
  FontFamily(v-model="fontFamily")
  slot(name="colors")
  slot(name="afterColors")
  template(#moreOrLess v-if="needMoreOrLess")
    RangeInput(:label="$t('fontSize')" :min="8" :max="90" :step="1" v-model="fontSize")
    template(v-if="$slots.styles")
      slot(name="styles")
    template(v-if="needsFontStyle && !$slots.styles")
      FontStyle(
        :label="$t('style')"
        :textWeight.sync="textWeight"
        :fontItalic.sync="fontItalic"
        :textDecoration.sync="textDecoration"
        :showTextStrikeThrough="showTextStrikeThrough"
        :editMobile="editMobile"
      )
    Align(
      v-if="!hideAlign"
      textAlign
      :labelOverride="$t('textAlign')"
      v-model="textAlign"
      :editMobile="editMobile"
    )
</template>
<script>
  import { mapState } from 'vuex';
  import itemMixin from '@/editor/mixins/item';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import Heading from '@/components/Editor/Heading.vue';
  import FontStyle from '@/components/Editor/FontStyle/FontStyle.vue';
  import RangeInput from '@/components/Editor/RangeInput/RangeInput.vue';
  import FontFamily from '../Controls/FontFamily.vue';
  import Align from '../Controls/Alignments/Align.vue';

  export default {
    components: {
      Accordion,
      Heading,
      FontFamily,
      FontStyle,
      RangeInput,
      Align,
    },
    mixins: [itemMixin],
    props: {
      fontFamilyProperty: {
        type: String,
        default: 'desktop.fontFamily',
      },
      fontSizeProperty: {
        type: String,
        default: 'desktop.fontSize',
      },
      textWeightProperty: {
        type: String,
        default: 'desktop.textWeight',
      },
      fontItalicProperty: {
        type: String,
        default: 'desktop.fontItalic',
      },
      textDecorationProperty: {
        type: String,
        default: 'desktop.textDecoration',
      },
      textAlignProperty: {
        type: String,
        default: 'desktop.textAlign',
      },
      hideStyle: {
        type: Boolean,
        default: false,
      },
      hideAlign: {
        type: Boolean,
        default: false,
      },
      needMoreOrLess: {
        type: Boolean,
        default: true,
      },
      showTextStrikeThrough: {
        type: Boolean,
        default: false,
      },
      editMobile: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapState(['mobilePreview']),
      needsFontStyle() {
        return (
          !this.hideStyle &&
          this.textWeightProperty &&
          this.fontItalicProperty &&
          this.textDecorationProperty
        );
      },
      fontFamily: {
        get() {
          return this.getValueOf(this.fontFamilyProperty);
        },
        set(v) {
          this.setValueOf(this.fontFamilyProperty, v);
        },
      },
      fontSize: {
        get() {
          const mobileFontSize = this.getValueOf(
            this.fontSizeProperty.replace('$device', 'mobile'),
          );
          if (!mobileFontSize && this.mobilePreview) {
            return Number(this.getValueOf(this.fontSizeProperty.replace('$device', 'desktop')));
          }
          return Number(this.getValueOf(this.fontSizeProperty));
        },
        set(v) {
          this.setValueOf(this.fontSizeProperty, v);
        },
      },
      textWeight: {
        get() {
          return this.getValueOf(this.textWeightProperty);
        },
        set(v) {
          this.setValueOf(this.textWeightProperty, v);
        },
      },
      fontItalic: {
        get() {
          return this.getValueOf(this.fontItalicProperty);
        },
        set(v) {
          this.setValueOf(this.fontItalicProperty, v);
        },
      },
      textDecoration: {
        get() {
          return this.getValueOf(this.textDecorationProperty);
        },
        set(v) {
          this.setValueOf(this.textDecorationProperty, v);
        },
      },
      textAlign: {
        get() {
          return this.getValueOf(this.textAlignProperty);
        },
        set(v) {
          this.setValueOf(this.textAlignProperty, v);
        },
      },
    },
  };
</script>
