<template lang="pug">
InputGroupControl(
  ref="reference"
  :label="labelOverride || $t('margin')"
  type="margin"
  :disabled="disabled"
  @focus="onFocus"
  :customProperty="customProperty"
  :editMobile="deviceSelector"
  :allowNegative="allowNegative"
)
</template>

<script>
  import { mapState } from 'vuex';
  import InputGroupControl from './InputGroup.vue';

  const DIM_HEIGHT_PX = 262;

  export default {
    components: { InputGroupControl },
    props: {
      labelOverride: {
        type: String,
        default: null,
      },
      customProperty: {
        type: String,
        default: null,
      },
      deviceSelector: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Array,
        default: () => [false, false, false, false],
      },
      allowNegative: {
        type: Boolean,
        default: true,
      },
    },
    data: () => ({
      activated: false,
    }),
    computed: {
      ...mapState(['selectedElement']),
    },
    methods: {
      onFocus() {
        if (!this.$refs.reference || !this.selectedElement) return;

        if (
          ['OmPage', 'OmColumn', 'OmRow'].includes(this.selectedElement?.type) ||
          this.selectedElement?.isTeaser
        ) {
          return;
        }

        this.activated = true;
        const inputs = this.$refs.reference.$el;
        const header = inputs.closest('.editor-accordion');
        const rect = JSON.parse(JSON.stringify(header.getBoundingClientRect()));

        const windowHeight = window.innerHeight;
        const calculatedEnding = rect.top + rect.height + 10;

        if (windowHeight < calculatedEnding) {
          rect.top = windowHeight - DIM_HEIGHT_PX - 10;
        }

        this.$bus.$emit('setMarginPopover', rect);
      },
    },
  };
</script>
